




































.anchor-container {
  padding-top: 90px;
}
.banner {
  background: url(https://mktv-in-cdn.mockuai.com/17007253254142839.png) no-repeat center;
  height: 680px;
    background-size: 100% 100%;
  &-inner {
    width: 1440px;
    height: 680px;
    background-size: 100% 100%;
    position: relative;
    margin: 0 auto;
  }
  .desc-img {
    position: absolute;
    top: 161px;
    left: 0;
    width: 672px;
    height: 286px;
  }
}
.content {
  width: 1440px;
  margin: 0 auto;
  padding: 40px 0;
  img {
    display: block;
    height: 620px;
    margin-bottom: 20px;
  }
}
